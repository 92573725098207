<template>
  <v-card flat class="my-6">
    <v-card outlined class="d-flex align-center justify-center mt-6">
      <v-card flat tile width="100%">
        <v-card-title
          class="text-subtitle-1 lightgray px-3 py-1 justify-center"
        >
          Total
          <v-spacer></v-spacer>
          <div class="text-h6 mr-4">
            <span v-if="venda.moeda_sigla === 'G$'">
              {{ venda.moeda_sigla }} {{ venda.total | guarani }}
            </span>
            <span v-else>
              {{ venda.moeda_sigla }} {{ venda.valor | currency }}
            </span>
          </div>
        </v-card-title>
      </v-card>
      <v-divider vertical></v-divider>
      <v-card flat tile width="100%">
        <v-card-title
          class="text-subtitle-1 lightgray px-3 py-1 justify-center"
        >
          Restante
          <v-spacer></v-spacer>
          <div class="text-h6 mr-4">
            <span v-if="venda.moeda_sigla === 'G$'">
              {{ venda.moeda_sigla }} {{ totalRestante | guarani }}
            </span>
            <span v-else>
              {{ venda.moeda_sigla }} {{ totalRestante | currency }}
            </span>
          </div>
        </v-card-title>
      </v-card>
      <v-divider vertical></v-divider>
      <v-card flat tile width="100%">
        <v-card-title
          class="text-subtitle-1 lightgray px-3 py-1 justify-center"
        >
          Recebido
          <v-spacer></v-spacer>
          <div class="text-h6 mr-4">
            <span v-if="venda.moeda_sigla === 'G$'">
              {{ venda.moeda_sigla }} {{ totalPagamentos | guarani }}
            </span>
            <span v-else>
              {{ venda.moeda_sigla }} {{ totalPagamentos | currency }}
            </span>
          </div>
        </v-card-title>
      </v-card>
    </v-card>
    <BaixarVendasAddFormaPago
      @push-pagamento="pushPagamentoItens"
      :valor-restante="totalRestante"
      :moeda_conta="venda.moeda_sigla"
      :cliente_id="venda.cliente_id"
      :numeros_cheque="numeros_cheques"
    />

    <v-card class="my-6" outlined>
      <v-card-title class="text-subtitle-1 px-3 py-2 lightgray">
        Formas de Pagamento
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headerFormaPagamentoItems"
          :items="formaPagamentoItems"
          :items-per-page="5"
          disable-sort
          :key="atualizaHeader"
          class="data-tables data-tables__row-click"
          :hide-default-footer="formaPagamentoItems.length > 5 ? false : true"
        >
          <template v-slot:item.valor="{ item }">
            {{ item.moeda }}
            <span v-if="item.moeda === 'G$'">
              {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ item.valor | currency }}
            </span>
          </template>

          <template v-slot:item.consolidado="{ item, index }">
            <div v-if="!editConsolidado || indexEditConsolidado != index">
              {{ venda.moeda_sigla }}
              <span v-if="venda.moeda_sigla === 'G$'">
                {{ item.consolidado | guarani }}
              </span>
              <span v-else>
                {{ item.consolidado | currency }}
              </span>
              <v-btn
                @click="editarConsolidado(item.consolidado, index)"
                icon
                x-small
              >
                <v-icon> mdi-pencil-outline </v-icon>
              </v-btn>
            </div>

            <v-text-field
              v-show="editConsolidado && indexEditConsolidado === index"
              v-model="consolidadoEdicao"
              label="Consolidado"
              ref="consolidadoEdicao"
              outlined
              dense
              hide-details
              style="width: 250px"
              class="input-right my-1 ml-4"
              v-currency="vCurrencyOptions"
            >
              <template v-slot:append>
                <v-btn
                  v-show="editConsolidado && indexEditConsolidado === index"
                  @click="salvaConsolidado(index)"
                  icon
                  x-small
                >
                  <v-icon> mdi-check </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </template>

          <template v-slot:item.actions="{ index }">
            <v-btn icon @click="removeFormaPagamento(index)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <div v-if="totalRestante < 0">
      <v-tooltip top color="darkblue">
        <template v-slot:activator="{ on }">
          <div v-on="on" class="d-flex align-center justify-center">
            <v-checkbox
              v-model="gerar_antecipo"
              :label="$tc('global.gerar') + ' ' + $tc('global.antecipo')"
            >
            </v-checkbox>

            <v-icon size="small">mdi-information-variant </v-icon>
          </div>
        </template>
        <br />
        <span> {{ $tc("global.avisoVendaAntecipo") }}: </span>
      </v-tooltip>

      <TrocoVenda
        v-if="!gerar_antecipo"
        :valor_restante="totalRestante"
        :venda_moeda_sigla="venda.moeda_sigla"
        :disableTroco.sync="disable_troco"
        :troco_real.sync="troco_real"
        :troco_guarani.sync="troco_guarani"
        :troco_dolar.sync="troco_dolar"
      ></TrocoVenda>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BaixaVenda",
  props: {
    venda: {
      type: Object,
    },
  },

  components: {
    BaixarVendasAddFormaPago: () => import("./BaixarVendasAddFormaPago.vue"),
    TrocoVenda: () => import("./TrocoVenda.vue"),
  },

  data() {
    return {
      loading: false,
      formaPagamentoItems: [],
      editConsolidado: false,
      consolidadoEdicao: null,
      indexEditConsolidado: null,
      gerar_antecipo: false,
      disable_troco: false,
      troco_dolar: 0,
      troco_guarani: 0,
      troco_real: 0,
      numeros_cheques: [],
      atualizaHeader: 0
    };
  },

  computed: {
    ...mapState("CaixaAf", {
      caixa_af: (state) => state.caixa_af,
    }),
    headerFormaPagamentoItems() {
      return [
        {
          text: "Formas de Pagamento",
          value: "forma_pagamento",
        },
        {
          text: "Destino",
          value: "destino",
        },
        {
          text: "Valor",
          align: "right",
          value: "valor",
        },
        {
          text: "Consolidado",
          align: "right",
          width: "250px",
          value: "consolidado",
        },
        {
          text: "",
          align: "center",
          value: "actions",
        },
      ];
    },

    totalPagamentos() {
      let total = this.formaPagamentoItems.reduce((accumulator, item) => {
        return accumulator + item.consolidado;
      }, 0);

      return total;
    },

    totalRestante() {
      let total = Number(this.venda.total) - this.totalPagamentos;
      return total;
    },

    vCurrencyOptions() {
      return {
        precision: undefined,
        distractionFree: false,
      };
    },
  },

  watch: {
    gerar_antecipo() {
      this.$emit("update:gerar_antecipo", this.gerar_antecipo);
      if (this.gerar_antecipo) {
        this.disable_troco = false;
        this.troco_dolar = 0;
        this.troco_guarani = 0;
        this.troco_real = 0;
      }
    },
    disable_troco() {
      this.$emit("update:disable-troco", this.disable_troco);
    },
    troco_dolar() {
      this.$emit("update:troco_dolar", this.troco_dolar);
    },
    troco_guarani() {
      this.$emit("update:troco_guarani", this.troco_guarani);
    },
    troco_real() {
      this.$emit("update:troco_real", this.troco_real);
    },
  },

  methods: {
    pushPagamentoItens(item) {

      if(item.cheque){
        this.numeros_cheques.push(item.cheque.numero);

        let newHeader = [
          {
            text: this.$tc("global.banco"),
            value: "cheque.banco_nome",
          },
          {
            text: this.$tc("global.numero"),
            value: "cheque.numero",
          },
          {
            text: this.$tc("global.vencimento"),
            value: "cheque.data_vencimento",
          },
          {
            text: this.$tc("global.emitidoPor"),
            value: "cheque.emissor_nome",
          },
        ];
        this.headerFormaPagamentoItems.splice(2, 0, ...newHeader);
        this.atualizaHeader++;

      }

      item.destino = this.caixa_af.caixa_descricao;
      item.destino_id = this.caixa_af.id;
      this.formaPagamentoItems.push(item);
      this.$emit("update:formas-pagamentos", this.formaPagamentoItems);
      this.$emit("update:valor-restante", this.totalRestante);
    },

    removeFormaPagamento(index) {
      this.formaPagamentoItems.splice(index, 1);
      this.$emit("update:formas-pagamentos", this.formaPagamentoItems);
      this.$emit("update:valor-restante", this.totalRestante);
    },

    editarConsolidado(item, index) {
      this.indexEditConsolidado = index;

      this.$ci.setValue(
        this.$refs.consolidadoEdicao,
        Number(this.formaPagamentoItems[this.indexEditConsolidado].consolidado)
      );

      this.editConsolidado = true;
    },

    salvaConsolidado(index) {
      this.formaPagamentoItems[index].consolidado = this.$ci.parse(
        this.consolidadoEdicao,
        this.vCurrencyOptions
      );
      this.indexEditConsolidado = null;
      this.consolidadoEdicao = null;
      this.editConsolidado = false;
      this.$emit("update:valor-restante", this.totalRestante);
    },
  },

  mounted() {
    this.formaPagamentoItems = [];
    let pagamento = {};
    pagamento.cambio = 1;
    pagamento.consolidado = this.totalRestante;
    pagamento.destino = this.caixa_af.caixa_descricao;
    pagamento.destino_id = this.caixa_af.id;
    pagamento.forma_de_pagamento_id = 1;
    pagamento.forma_pagamento = "DINHEIRO";
    pagamento.moeda = this.venda.moeda_sigla;
    pagamento.valor = this.totalRestante;
    this.formaPagamentoItems.push(pagamento);
    this.$emit("update:formas-pagamentos", this.formaPagamentoItems);
    this.$emit("update:valor-restante", this.totalRestante);
  },
};
</script>

<style></style>
